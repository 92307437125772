





























import { ProcessSuggestPostAction } from '@/includes/types/Post.types'
import { PostService } from '@/includes/services/PostService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { Component, Emit, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import Vue from 'vue'

@Component({
  data() {
    return {
      ProcessSuggestPostAction
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class SuggestPostActionButton extends Vue {

  @Prop({ type: String, required: true }) postKey!: string

  @Emit('onApplySuggestStart')
  onApplySuggestStart():void {}

  @Emit('onApplySuggestDone')
  onApplySuggestDone(result:any):any {
    return result
  }

  getBoardById!:GetBoardByIdType

  processSuggestPost(action: ProcessSuggestPostAction) {
    this.onApplySuggestStart()

    PostService.applySuggestPost('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      action: action,
      post_key: this.postKey
    })
      .then((data) => {
        successNotification()
        this.onApplySuggestDone(data)
      })
      .catch((error:any) => {
        errorNotification(error)
        this.onApplySuggestDone(error)
      })
      .finally(() => this.$store.dispatch('requestSuggestNotifications'))
  }
}
