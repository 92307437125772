
































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import { PostService } from '@/includes/services/PostService'
import { PostActionType, PostData, PostState, PostType } from '@/includes/types/Post.types'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'
import DatesTable from '@/components/DatesTable.vue'
import Quizes from '@/components/Quizes.vue'
import PostInfo from '@/components/PostInfo.vue'
import PageTitle from '@/components/PageTitle.vue'
import AsyncPopup from '@/components/AsyncPopup.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { isEditButtonVisible, postColor, postObjectFabric, setUniqueDates } from '@/includes/PostHelpers'
import PostTypePicker from '@/components/Post/PostTypePicker.vue'
import {
  canAccessPublished,
  canCreatePost,
  canEditAndDeletePost,
  canViewOtherPosts,
  createPostButtonTitle
} from '@/includes/PermissionHelper'
import SuggestPostActionButton from '@/components/SuggestPostActionButton.vue'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import customView from '@/views/custom-fullcalendar-plugin/customView'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import isMobile from 'piramis-js-utils/lib/isMobile'

import FullCalendar, { CalendarOptions, EventClickArg, EventInput } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import enLocale from '@fullcalendar/core/locales/en-gb'
import listPlugin from '@fullcalendar/list'
import ruLocale from '@fullcalendar/core/locales/ru'
import { LocaleInput } from '@fullcalendar/common'
import Component from 'vue-class-component'
import { Mixins, Ref, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import { mapGetters } from 'vuex'

@Component({
  data() {
    return {
      createPostButtonTitle,
      canCreatePost,
      canViewOtherPosts,
      canEditAndDeletePost,
      canAccessPublished,
      CalendarPostsSource,
      PostState
    }
  },
  components: {
    SuggestPostActionButton,
    PostTypePicker,
    AsyncPopup,
    PostInfo,
    PageTitle,
    Quizes,
    FullCalendar,
    MobileLandscapeTriggerLayout,
    DatesTable,
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class PostsPlanner extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  getBoardById!:GetBoardByIdType

  isSidebarOpen = false

  currentCustomView = !!isMobile()

  selectedNode: PostData | null = null

  newModel: Record<string, any> = {
    date: '',
    time: '',
    targets: '',
  }

  eventsByDate: Array<PostData> = []

  isMounted = false

  allTheEvents: Array<PostData & EventInput> = []

  selectedDate = ''

  isLoading = false

  isPostTypeModalOpen = false

  query: any = null

  drawerLoading = false

  historyComponentChange = false

  @Ref('movePostModal') movePostModal!: AsyncPopup

  @Watch('$i18n.locale')
  onLocaleChange(code: string): void {
    this.calendarApi.setOption('locale', code)
  }

  @Watch('$route.params.id')
  onIdChange():void {
    this.calendarApi.refetchEvents()
  }

  get isCopyPostButtonVisible():boolean {
    if (this.selectedNode) {
      return canCreatePost()
    }

    return false
  }

  get isEditPostButtonVisible(): boolean {
    if (this.selectedNode) {
      return isEditButtonVisible(this.selectedNode as PostData)
        && ((this.selectedNode.state === 'Active' && canEditAndDeletePost())
          || (this.selectedNode.state === 'Complete' && canAccessPublished()))
    }

    return false
  }

  get isRemoveButtonVisible(): boolean {
    const postState = this.selectedNode!.state

    if (postState === PostState.Complete) {
      return canAccessPublished()
    }

    if (postState === PostState.Active || postState === PostState.Error) {
      return canEditAndDeletePost()
    }

    return false
  }

  get sidebarTitle(): string {
    if (this.selectedNode) {
      return this.$t('planner_page_post_preview').toString()
    } else {
      return this.$t('posts_planner_page_title').toString()
    }
  }

  get calendarApi() {
    return (this.$refs.fullCalendar as InstanceType<typeof FullCalendar>).getApi()
  }

  get getFullCalendarLocale(): LocaleInput {
    return localStorage.getItem('locale') === 'ru' ? ruLocale : enLocale
  }

  handlePostCardClick(post: PostData): void {
    this.historyComponentChange = true
    this.selectedNode = post
  }

  gotoSuggestedPost(post: PostData): void {
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'show-suggest'
      },
      query: {
        type: post.post.message.type.toLowerCase(),
        postId: post.key
      }
    })
  }

  gotoEditSuggestedPost(post: PostData): void {
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'edit-suggest'
      },
      query: {
        type: post.post.message.type.toLowerCase(),
        postId: post.key
      }
    })
  }

  handleSuggestStart(): void {
    this.drawerLoading = true
  }

  handleSuggestDone(): void {
    this.drawerLoading = false
    this.isSidebarOpen = false

    this.calendarApi.refetchEvents()
  }

  getMainEvents(fetchInfo, successCallback): void {
    this.isLoading = true

    this.getDataFromApi(moment(fetchInfo.start).format('YYYY-MM-DD'), moment(fetchInfo.end).format('YYYY-MM-DD'))
      .then(returnEvents => {
        let events = [ ...returnEvents ]

        if (this.calendarApi.view.type === 'timeGridWeek') {
          events = this.roundPostsTime(events)
        }

        successCallback(events)
      })
      .finally(() => this.isLoading = false)
  }

  setCalendarEventsQuery(source: CalendarPostsSource): void {
    this.$router.replace({ path: this.$route.path, query: { calendar: source } }).catch(() => {
    })
  }

  getDeletedEvents(fetchInfo, successCallback): void {
    this.isLoading = true

    PostService.getDeletedPosts('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      interval: {
        interval_type: 'Full',
        from: moment(fetchInfo.start).format('YYYY-MM-DD'),
        to: moment(fetchInfo.end).format('YYYY-MM-DD')
      },
    })
      .then(({ posts }) => {

        const postsFromApi = cloneDeep(posts)
        const postsForCalendar: Array<PostData & EventInput> = []

        let recurrentEvents: Array<PostData & EventInput> = []

        postsFromApi.forEach(post => {
          if (post.run_time && post.run_time.length === 1) {
            postsForCalendar.push({
              ...post,
              start: post.run_time[0],
              allDay: false,
              id: post.key,
            })
          } else {
            if (!recurrentEvents.length) {
              recurrentEvents = this.setDates(post)
            } else {
              recurrentEvents.push(...this.setDates(post))
            }
          }
        })

        postsForCalendar.push(...recurrentEvents)

        successCallback(postsForCalendar)

      })
      .finally(() => this.isLoading = false)
  }

  getSuggestedEvents(fetchInfo, successCallback): void {
    this.isLoading = true

    PostService.getSuggestPosts('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      interval: {
        interval_type: 'Full',
        from: moment(fetchInfo.start).format('YYYY-MM-DD'),
        to: moment(fetchInfo.end).format('YYYY-MM-DD')
      },
    })
      .then(returnEvents => {
        let posts: any[] = []

        returnEvents.posts.forEach(post => {
          if (post.run_time) {
            posts.push({
              ...post,
              start: post.run_time,
              allDay: false,
              id: post.key,
            })
          }
        })

        successCallback(posts)
      })
      .finally(() => this.isLoading = false)
  }

  sidebarCloseHandler(): void {
    this.isSidebarOpen = false
    this.selectedNode = null
    this.eventsByDate = []
    this.historyComponentChange = false
  }

  isPostPinned(post: PostData): boolean {
    let result = false
    if (post.post.message.type === PostType.Post) {
      result = post.post.message.variants.some(v => v.pin)

      if (post.post.delayed_actions?.length) {
        result = post.post.delayed_actions?.some(a => a.action.type === PostActionType.PinMessageAction)
      }
      if (post.post.reaction_actions?.length) {
        result = post.post.reaction_actions?.some(a => a.action.type === PostActionType.PinMessageAction)
      }
    }

    return result
  }

  handleEditPost(selectedPost: PostData): void {
    if (selectedPost.state === PostState.Active) {
      this.gotoPost(selectedPost, 'edit')
    }
    if (selectedPost.state === PostState.Complete) {
      this.gotoPost(selectedPost, 'edit-published')
    }
  }

  handleRemovePost(selectedPost: EventInput): void {
    if (selectedPost.state === 'Active') {
      this.removeEvent(selectedPost.key)
    }
    if (selectedPost.state === 'Complete' || selectedPost.state === 'Error') {
      this.removePublishedEvent(selectedPost.key)
    }
  }

  removePublishedEvent(postKey: string) {
    const h = this.$createElement
    this.$confirm({
      title: this.$t('planner_popup_title_remove_published_warn').toString(),
      content: h('div', {}, [
        h('div', { class: 'ant-alert ant-alert-info ant-alert-no-icon' }, this.$t('remove_published_post_warning_message').toString()),
        h('p', { class: 'mt-2' }, this.$t('planner_popup_text_remove_published_warn').toString()),
      ]),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        const event = this.calendarApi.getEventById(postKey)
        if (event) {
          PostService.deletePublishedPost('tg', { board_key: this.getBoardById(this.$route.params.id)!.board, post_key: postKey })
            .then(() => {
              successNotification()
              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isSidebarOpen = false
        this.sidebarCloseHandler()
      },
      centered: true
    })
  }

  removeEvent(postKey: string): void {
    this.$confirm({
      title: this.$t('planner_popup_title_remove_warn').toString(),
      content: this.$t('planner_popup_remove_warn').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        const event = this.calendarApi.getEventById(postKey)
        if (event) {
          PostService.deletePost('tg', { board_key: this.getBoardById(this.$route.params.id)!.board, post_key: event.id })
            .then(() => {
              successNotification()
              this.calendarApi.refetchEvents()
            })
            .catch(errorNotification)
        }
        this.isSidebarOpen = false
        this.sidebarCloseHandler()
      },
      centered: true
    })
  }

  gotoPost(data: PostData, mode: 'edit' | 'copy' | 'edit-published', query?: Record<string, string>) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: mode
      },
      query: { postId: data.key, ...query }
    })
  }

  handleTemplateButtonClick():void {
    this.$router.push({
      name: "Style",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  postTime(date: string): string {
    return moment(date).format('HH:mm').toString()
  }

  makeTextPreview(text: string): string {
    const parser = new DOMParser()
    return parser.parseFromString(text, 'text/html').body.innerText.slice(0, 50)
  }

  get calendarOptions(): CalendarOptions {
    return {
      plugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        listPlugin,
        customView
      ],
      buttonText: {
        custom: this.$t('feed_calendar_view').toString()
      },
      customButtons: {},
      headerToolbar: {
        left: 'today',
        center: 'prev title next',
        right: `dayGridMonth,timeGridWeek,custom`
      },
      initialView: isMobile() ? 'custom' : 'dayGridMonth',
      initialEvents: (arg, successCallback) => {
        successCallback([])
      },
      editable: true,
      selectable: true,
      selectMirror: true,
      initialDate: this.$route.query.date ? this.$route.query.date.toString() : undefined,
      weekends: true,
      eventClick: this.handleEventClick,
      navLinks: true,
      dateClick: (info) => this.handleDateClick(moment(info.date).format('YYYY-MM-DD')),
      locale: this.getFullCalendarLocale,
      locales: [ enLocale, ruLocale ],
      contentHeight: 1000,
      events: (fetchInfo, successCallback) => {
        setTimeout(() => {
          if (this.calendarApi.currentData.currentViewType !== 'custom') {
            this.getPostsByCalendarType(fetchInfo, successCallback)
          }
        }, 0)
      },
      viewDidMount: (mountArg) => {
        this.currentCustomView = mountArg.view.type === 'custom'
      },
      dayMaxEvents: true,
      views: {
        custom: {
          type: 'custom',
        }
      },
      eventMaxStack: 3,
      lazyFetching: false,
      moreLinkClick: (info) => this.handleDateClick(moment(info.date).format('YYYY-MM-DD')),
      eventClassNames: (arg) => {
        return postColor((arg.event.extendedProps as PostData), arg.backgroundColor)
      },
      eventDrop: (info) => {
        this.setDateTimeToNewModel(info.event.startStr)

        this.movePostModal.open()
          .then(() => {
            this.movePost(info.event.extendedProps.key)
            successNotification()
          })
          .catch(() => info.revert())
      },
      eventAllow: (dropLocation, draggedEvent) => {
        if (draggedEvent?.extendedProps.run_time.length > 1) {
          if (moment(dropLocation.startStr).isSameOrBefore(moment().startOf('d')) ||
            moment(draggedEvent?.startStr).format('YYYY-MM-DD HH:mm') !== moment(draggedEvent?.extendedProps.run_time[0]).format('YYYY-MM-DD HH:mm')
          ) {
            return false
          }
          return true
        } else {
          return !moment(dropLocation.startStr).isSameOrBefore(moment().startOf('d'))
        }
      },
      moreLinkContent: (args) => {
        return '+' + args.num
      }
    }
  }

  movePost(post_key: string): void {
    PostService.movePost('tg', {
      board_key: this.getBoardById(this.$route.params.id)!.board,
      post_key,
      time: moment(`${ this.newModel.date } ${ this.newModel.time }`).format('YYYY-MM-DD HH:mm:ss')
    })
      .then(() => this.calendarApi.refetchEvents())
      .catch(errorNotification)
  }

  roundPostsTime(posts: Array<PostData & EventInput>): Array<PostData & EventInput> {
    return posts.map(p => {
      if (moment(p.start).minute() >= 30) {
        p.start = moment(p.start).minute(30).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      } else {
        p.start = moment(p.start).minute(0).second(0).format('YYYY-MM-DD HH:mm:ss').toString()
      }

      return p
    })
  }

  setDateTimeToNewModel(date: string): void {
    const formatDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    this.newModel.date = formatDate.split(' ')[0]
    this.newModel.time = formatDate.split(' ')[1]
  }

  handleDateClick(date: string) {
    this.isSidebarOpen = true
    this.eventsByDate = []

    this.selectedDate = date

    this.allTheEvents.forEach(event => {
      if (typeof event.start === 'string' && event.start.split(' ')[0] === this.selectedDate) {
        this.eventsByDate.push(event)
      }
    })
  }

  postTypeSelectHandler(data: { type: PostType, query: Record<string, any> | null }) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'new',
      },
      query: {
        ...this.query ? this.query : {},
        type: data.type.toLowerCase(),
        ...data.query
      },
    })
  }

  gotoCreatePostPage(queryParams?: Record<string, string>): void {
    this.isPostTypeModalOpen = true
    this.query = queryParams
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.isSidebarOpen = true
    if (this.calendarApi.view.type !== 'timeGridWeek') {
      this.setDateTimeToNewModel(clickInfo.event.startStr)
    } else {
      this.setDateTimeToNewModel(clickInfo.event.extendedProps.time)
    }

    this.selectedNode = cloneDeep(clickInfo.event.extendedProps as PostData)
    this.newModel.targets = this.selectedNode.targets
  }

  getDataFromApi(from: string, to: string): Promise<Array<PostData & EventInput>> {
    return new Promise((resolve, reject) => {
      PostService.getPosts('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        interval: { interval_type: 'Full', from, to },
      })
        .then(({ posts }) => {
          const postsFromApi = cloneDeep(posts)
          const postsForCalendar: Array<PostData & EventInput> = []

          let recurrentEvents: Array<PostData & EventInput> = []

          postsFromApi.forEach(post => {
            if (post.run_time && post.run_time.length === 1) {
              postsForCalendar.push({
                ...post,
                start: post.run_time[0],
                allDay: false,
                id: post.key,
              } as PostData & EventInput)
            } else {
              if (!recurrentEvents.length) {
                recurrentEvents = this.setDates(post)
              } else {
                recurrentEvents.push(...this.setDates(post))
              }
            }
          })

          postsForCalendar.push(...recurrentEvents)

          this.allTheEvents = cloneDeep(postsForCalendar)

          resolve(postsForCalendar)
        })
        .catch(reject)
    })
  }

  setDates(post: PostData): Array<PostData & EventInput> {
    const dates = [ ...post.run_time ]

    if (this.calendarApi.view.type !== 'dayGridMonth') {
      return dates.map(date => postObjectFabric(date, post))
    } else {
      return setUniqueDates(dates, post)
    }
  }

  getPostsByCalendarType(fetchInfo, successCallback): void {
    const calendarQuery = this.$route.query.calendar?.toString() as CalendarPostsSource

    if (calendarQuery === CalendarPostsSource.Schedule) {
      this.getMainEvents(fetchInfo, successCallback)
    } else if (calendarQuery === CalendarPostsSource.Suggested) {
      this.getSuggestedEvents(fetchInfo, successCallback)
    } else if (calendarQuery === CalendarPostsSource.Deleted) {
      this.getDeletedEvents(fetchInfo, successCallback)
    } else {
      this.$router.replace({ path: this.$route.path, query: { calendar: CalendarPostsSource.Schedule } }).catch(() => {
      })
    }
  }

  mounted() {
    if (this.$store.state.shopState.products === null) {
      this.$store.dispatch('getProducts')
    }

    this.isMounted = true
  }
}
