import { PostData, PostState } from '@/includes/types/Post.types'
import { EventInput } from '@fullcalendar/vue'

export function postColor(post:PostData, color:string): Array<string> {
    if (color !== 'null' && color) {
        return [ color ]
    } else {
        if (post.state === PostState.Complete) {
            return [ 'complete' ]
        }

        if (post.state === PostState.Active && post.run_time && post.run_time.length === 1 && !post.schedule.period) {
            return [ 'regular' ]
        }

        if (post.state === PostState.Error) {
            return [ 'error' ]
        }

        if (post.run_time) {
            // todo
            if (!post.has_posts_outside_interval && (post as any).time === post.run_time.at(-1)) {
                return [ 'repeat-last' ]
            }

            return [ 'repeat' ]
        }

        if ([ PostState.PublishedDeleted, PostState.Deleted ].includes(post.state)) {
            return [ '' ]
        }

        return [ 'regular' ]
    }
}

export function isEditButtonVisible(post: PostData): boolean {
    if (post.state === PostState.Error) return false
    if (post.state === PostState.Complete) return post.post.message.type === 'Post'
    return true
}

export function setUniqueDates(dates: Array<string>, post: PostData): Array<PostData & EventInput> {
    const set = new Set(dates.map((d: string) => d.split(' ')[0]))
    const fullUniqueDates: Array<string> = []

    set.forEach((time: any) => {
        const idx = dates.findIndex((r: any) => r.split(' ')[0] === time)
        fullUniqueDates.push(dates[idx])
    })
    return fullUniqueDates.map(date => postObjectFabric(date, post))
}

export function postObjectFabric(date: string, post: PostData): PostData & EventInput {
    return {
        ...post,
        id: post.key,
        start: date,
        time: date,
        allDay: false,
    } as PostData & EventInput
}
