




































import { PostData, PostType } from '@/includes/types/Post.types'
import { postColor } from '@/includes/PostHelpers'
import formatTime from '@/includes/formatTime'
import { createPostButtonTitle } from '@/includes/PermissionHelper'
import i18n from '@/i18n/i18n'
import PageTitle from '@/components/PageTitle.vue'

import Vue from 'vue'
import { sortBy } from 'lodash'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component({
  components: { PageTitle },
  data() {
    return {
      postColor,
      formatTime,
      createPostButtonTitle,
      moment,
    }
  }
})
export default class DatesTable extends Vue {

  @Prop() dateEvents !: Array<PostData>

  @Prop({ required: true, type: String }) selectedDate !: string

  @Prop() timezone !: string

  @Emit('createPost')
  createPost(queryParams: Record<string, string>): Record<string, string> {
    return queryParams
  }

  get formatDate() {
    moment.locale(i18n.locale)
    return moment(this.selectedDate).format('D MMMM')
  }

  @Emit('onPostCardClick')
  onPostSelect(post:PostData):PostData {
    return post
  }

  get sortedPostsByTime():Array<PostData> {
    return sortBy(this.dateEvents, (item) => item.run_time[0])
  }

  previewText(post: PostData):string {
    if (post.post.message.type === PostType.Post || post.post.message.type === PostType.Paid) {
      const firstMessageText = post.post.message.variants[0].text

      if (firstMessageText.length > 50)
        return `${ post.post.message.variants[0].text.slice(0, 50) }...`
      else
        return firstMessageText
    } else {
      return post.post.message.text
    }
  }

  goToCreatePage(time = moment().add('1', 'h').startOf('minute').format('HH:mm:ss')) {
    const queryParams = {
      post_date: this.selectedDate,
      post_time: time,
    }

    this.createPost(queryParams)
  }
}
