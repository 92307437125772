





































































import { PostService } from '@/includes/services/PostService'
import { errorNotification } from '@/includes/services/NotificationService'
import { OrderedPostData, PostState } from '@/includes/types/Post.types'
import TelegramPostPreview from "@/views/PostFeed/TelegramPostPreview.vue";
import { BoardTargetOptionsType, GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import Component from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { mapGetters } from 'vuex'

@Component({
  components: { TelegramPostPreview },
  data() {
    return {
      TelegramPostPreview,
      PostState
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById', 'boardTargetOptions' ])
  }
})
export default class PostInfo extends Mixins<UseFields>(UseFields) {
  @Prop() post!: OrderedPostData

  getBoardById!:GetBoardByIdType

  boardTargetOptions!:BoardTargetOptionsType

  postInfo: Array<{ target: { targetName: string, targetAvatar: string }, error: string | null, time: string }> | null = null

  isLoading = false

  currentPost: null | OrderedPostData = null

  get currentPostRuntime():string {
    if (Array.isArray(this.currentPost!.run_time)) {
      return this.currentPost!.run_time[0]
    }

    return this.currentPost!.run_time
  }

  get getChannels():Array<SelectOptionData> {
    if (this.post && this.post && this.post.targets) {
      return this.boardTargetOptions(this.$route.params.id).filter(target => this.post!.targets.includes(target.value))
    }

    return []
  }

  created(): void {
    this.isLoading = true

    if (![ PostState.New, PostState.PublishedDeleted, PostState.Deleted, PostState.Rejected ].includes(this.post.state)) {
      PostService.getPostInfo('tg', {
        board_key: this.getBoardById(this.$route.params.id)!.board,
        post_key: this.post.key
      })
        .then(({ data, stat }: any) => {
          this.currentPost = Object.assign(data.post, { stat, run_time: [ data.post.schedule.run_time ] })
          this.postInfo = data.result.map((info) => {
            const target = this.boardTargetOptions(this.$route.params.id).find(target => target.value === info.targetId)

            return {
              error: info.error,
              time: moment(info.time).format('YYYY-MM-DD HH:mm:ss'),
              target: {
                targetAvatar: target!.image?.src,
                targetName: target!.label
              }
            }
          })
        })
        .catch(errorNotification)
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.currentPost = this!.post
      this.isLoading = false
    }
  }
}
